import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import AppRouter from './AppRouter';
import { NotificationStackWrapper, msalConfig } from './components';
import { CookiesProvider } from 'react-cookie';

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize().then(() => {
	const root = createRoot(document.getElementById('root'));
	root.render(
		<React.StrictMode>
			<MsalProvider instance={msalInstance}>
				<CookiesProvider>
					<NotificationStackWrapper>
						<AppRouter />
					</NotificationStackWrapper>
				</CookiesProvider>
			</MsalProvider>
		</React.StrictMode>
	);
});
