import React from 'react';
import stravaSvgSrc from "../img/logo/strava.svg";
import stravaPngSrc from "../img/logo/strava.png";
import { Alert, Grid, Box, Button, Container, Card, CardContent, Typography, Link } from '@mui/material';
import RegistrationStepper from "./RegistrationStepper";

class Registration extends React.Component {
    render() {
        let { step, target, updateProfile } = this.props;
        return (
            <Container maxWidth="none" sx={{ height: '100vh', padding: '0 !important' }}>
                <div className="bg-container">
                    <div className="bg-section">
                        <div className="bg-overlay"></div>
                    </div>
                    <div className="bg-section">
                        <div className="bg-overlay"></div>
                    </div>
                    <div className="bg-section">
                        <div className="bg-overlay"></div>
                    </div>
                    <div className="bg-section">
                        <div className="bg-overlay"></div>
                    </div>
                </div>
                <Card className="login-box" sx={{ maxWidth: 400 }}>
                    <CardContent sx={{ p: '0px!important' }}>
                        <Grid container spacing={0} mb={2} sx={{ backgroundColor: '#18181b', p: 1 }}>
                            <Grid item sx={{ display:'flex', alignItems:'center', borderRight: '1px solid #2f2f36', m: 1, pr: 2 }}>
                                <img
                                    style={{ width: 80 }}
                                    src="../img/logo/deloitte_digital.png"
                                    alt="Deloitte Digital Logo"
                                />
                            </Grid>
                            <Grid item sx={{ color: '#fff', m: 1 }}>
                                <Typography component="h1">
                                    <span className='app-name'>DD Global Challenge</span>
                                </Typography>
                                <Typography variant="caption" display="block">
                                    Find new ways to challenge yourself
                                </Typography>
                            </Grid>
                        </Grid>
                        {updateProfile ? 
                            <Box>
                                <Alert variant="outlined" severity="warning">
                                    <Typography variant="body2">
                                        Re-authenticate your <strong style={{ marginLeft: '3px'}}>Deloitte<span className='text-deloitte-green'>.</span></strong> account.<br/>
                                    </Typography>
                                    <Button sx={{mt:1}} onClick={ () => window.location.href = '/dlogin' } variant="contained" color="secondary">
                                        Login with <strong style={{ marginLeft: '3px'}}>Deloitte<span className='text-deloitte-green'>.</span></strong>
                                    </Button>
                                </Alert>
                            </Box>
                        :
                            <RegistrationStepper 
                                currentStep={ step } 
                                target={ target } 
                            />
                        }
                        <Typography sx={{ textAlign: 'center', mt: 3, mb: 1 }} variant="caption" display="block">
                            Need help? <Link className="text-deloitte-green" href="mailto:GlobalDDDistanceChallenge@deloitte.com?subject=Need%20help%20with%20DDGC%20Registration" color="inherit">Contact us</Link>
                        </Typography>
                        {!updateProfile &&
                            <Box sx={{ textAlign: 'center', backgroundColor: '#18181b' }}>
                                <img
                                    src={stravaPngSrc}
                                    srcSet={stravaSvgSrc}
                                    alt="Powered By Strava"
                                    style={{ maxWidth: "100px" }}
                                />
                            </Box>
                        }
                    </CardContent>
                </Card>
            </Container>
        )
    }
}

export default Registration;