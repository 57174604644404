import { AppBar, Box, Container, Toolbar, Tooltip, Typography, Link } from "@mui/material";

export default function HeaderStatic() {
	return (
		<AppBar position="static">
			<Container maxWidth="lg">
				<Toolbar disableGutters>
					<img
						className="logo"
						src="../img/logo/deloitte_digital.png"
						alt="Deloitte Digital Logo"
					/>
					<Tooltip title="We support Ukraine">
						<Box sx={{ width: 30, mr: 1 }} flex="none">
							<Box sx={{ height: 10 }} className="bg-ukraine-blue" />
							<Box sx={{ height: 10 }} className="bg-ukraine-yellow" />
						</Box>
					</Tooltip>
					<Typography fontWeight="bold" variant="h1">
						<Link href="/" color="inherit" underline="none">
							Global Challenge
						</Link>
					</Typography>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
