import { createTheme } from "@mui/material/styles";

export const darkTheme = createTheme({
	typography: {
		fontFamily: 'Open Sans, sans-serif',
		h1: {
			fontSize: '1rem',
			'@media (min-width:600px)': {
			  fontSize: '1.25rem',
			},
		},
		caption: {
			color: 'rgba(255, 255, 255, 0.70)',
		},
		overline: {
			lineHeight: 1
		},
		heroTitle: {
			fontSize: '1.25rem',
			maxWidth: '200px',
			'@media (min-width:600px)': {
			  fontSize: '1.5rem',
			  maxWidth: '340px',
			},
		},
		heroSubtitle: {
			maxWidth: '140px',
			fontSize: '0.875rem',
			'@media (min-width:600px)': {
			  maxWidth: '320px',
			  fontSize: '1rem'
			},
		}
	},
	palette: {
		mode: "dark",
		background: {
			default: '#0e0e10',
			transparent: 'rgba(31,31,35,0.90)'
		},
		primary: {
			main: "#86bc25",
			tabs: "#1f1f23",
		}
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					background: "#18181b",
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					paddingTop: '0'
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundImage: "none",
					background: "#1f1f23",
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					border: "1px solid #ffffff14",
					background: "#18181b",
				},
			},
		},
		MuiTable: {
			styleOverrides: {
				root: {
					background: "#18181b",
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					background: "#18181b",
				},
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				root: {
					background: "#18181b",
				},
			},
		},
		MuiListSubheader: {
			styleOverrides: {
				root: {
					backgroundColor: "#0e0e10",
					zIndex: 2
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					'&:hover': { backgroundColor: "transparent" },
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					color: 'rgba(0, 0, 0, 0.87)',
				},
				containedSecondary: {
					backgroundColor: '#2f2f36',
					color: '#fff',
					textTransform: 'none',
					'&:hover': {
						backgroundColor: '#3f3f46',
					},
				},
			},
		},
		MuiStepIcon: {
			styleOverrides: {
				text: {
					fill: '#fff'
				}
			}
		}
	},
});
