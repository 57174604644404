import React, { useState, useRef, useEffect } from "react";
import {
	Avatar,
	CardActionArea,
	Container,
	Badge,
	Grid,
	Select,
	MenuItem,
	Card,
	CardHeader,
	CardContent,
	Typography,
	OutlinedInput,
	Autocomplete,
	TextField,
	Skeleton,
	Box,
} from "@mui/material";
import AthleteProfileCard from "../components/AthleteProfileCard";
import ChallengesLeaderboard from "../components/ChallengesLeaderboard";

const LeaderboardPage = (props) => {
	const ref = useRef(null);
	const [topic, setTopic] = useState(props.topic);
	const [athlete, setAthlete] = useState(null);
	const [matches, setMatches] = useState([]);
	const [loading, setLoading] = useState(false);
	const [lastId, setLastId] = useState(null);
	const [searched, setSearched] = useState("");
	const [chosen, setChosen] = useState(null);

	const handleChangeTopic = (event) => {
		setTopic(event.target.value);
	};

	const retrieveAvatar = (row) => {
		let dim = -1;
		let index = props.dimensions.findIndex((d) => d.name === topic);
		if (index < 0) {
			dim = 0;
		} else {
			dim = props.dimensions[index].id;
		}
		let target = dim * 100 + row + 1;
		return props.avatars.find((a) =>
			a.in.split(";").map(Number).includes(target)
		).av;
	};

	const formatAmount = (value, dimension) => {
		let response = "";
		let hasUnit = ["Trophies", "Activities"].indexOf(dimension) < 0;
		let unitShort = hasUnit
			? props.dimensions.find((dim) => dim.name === dimension).unitShort
			: "";
		if (dimension === "Duration") {
			let mins = "0" + parseInt((value * 60) % 60);
			response =
				parseInt(value).toLocaleString("en-US") +
				" " +
				unitShort +
				" " +
				mins.substring(mins.length - 2) +
				" min";
		} else {
			response = Math.round(value).toLocaleString("en-US") + " " + unitShort;
		}
		return response;
	};

	const displayCard = (id) => {
		if (id && id !== lastId) {
			setMatches([]);
			setLoading(true);
			fetch(
				"api/getProfile/" + id + "?utcOffset=" + new Date().getTimezoneOffset(),
				{ accept: "application/json" }
			)
				.then((resp) => resp.json())
				.then((data) => {
					setAthlete(data);
					setLoading(false);
					setLastId(id);
				});
		}
	};

	const closeProfileCard = () => {
		setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 100);
		setMatches([]);
		setSearched("");
		setAthlete(null);
		setLastId(null);
		setChosen(null);
	};

	const handleSearchTermChange = (event) => {
		if (event && typeof event.target.value !== "undefined") {
			let newValue = event.target.value;
			setSearched(newValue);
			// Only load when we have 3 characters
			if (newValue.length === 3) {
				fetch("api/getAthletes?searchTerm=" + newValue, {
					accept: "application/json",
				})
					.then((resp) => resp.json())
					.then((data) => {
						setMatches(data);
					});
			} else {
				// If less than 3 characters and there is a previous list, remove it
				if (newValue.length < 3 && matches.length > 0) {
					setMatches([]);
					setChosen(null);
				}
			}
		}
	};

	const medalColors = ["#FFD700", "#eeeeee", "#b08d57"];
	const ranks = ["2", "1", "3"];

	useEffect(() => {
		if (
			loading &&
			document
				.getElementsByClassName("animate-content-card-up")[1]
				.getBoundingClientRect().top > window.innerHeight
		) {
			window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
		}
	}, [ref, loading]);

	return (
		<Container sx={{ my: 5 }} maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Card className="animate-content-card-up">
						<CardHeader
							action={
								<Select
									value={topic}
									onChange={handleChangeTopic}
									size="small"
									variant="filled"
									input={<OutlinedInput />}
								>
									{Object.keys(props.topAthletes).map((key, i) => (
										<MenuItem key={i} value={key}>
											{key}
										</MenuItem>
									))}
								</Select>
							}
							title={
								<Typography component="h2" fontWeight="bold">
									Top Athletes
								</Typography>
							}
						/>
						{topic !== "Challenges" ? (
							<CardContent>
								<Grid container sx={{ mb: 2, alignItems: "end" }}>
									{ranks.map((rank, index) => (
										<Grid
											item
											xs={4}
											key={index}
											order={rank}
											sx={{
												textAlign: "center",
												opacity: 0,
												animation: `slideInFromBottom 1.5s calc(((${index} * 0.1)) * 1s) cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards`,
											}}
										>
											<Card sx={{ border: "none", height: "320px" }}>
												<CardActionArea
													onClick={() =>
														displayCard(props.topAthletes[topic][index].id)
													}
													sx={{ height: "100%", pt: index * 3 }}
												>
													<CardContent sx={{ height: "100%", px: 1 }}>
														<Badge
															overlap="circular"
															sx={{
																border: `3px solid ${medalColors[index]}`,
																borderRadius: "50%",
															}}
															anchorOrigin={{
																vertical: "bottom",
																horizontal: "right",
															}}
														>
															<Avatar
																alt={props.topAthletes[topic][index].athlete}
																src={retrieveAvatar(index)}
																sx={{ width: 64, height: 64 }}
															/>
														</Badge>
														<Card
															sx={{
																background: `${medalColors[index]}50`,
																border: "none",
																width: "100%",
																height: "100%",
																mt: "-25px",
															}}
														>
															<CardContent sx={{ height: "calc(100% - 32px)" }}>
																<Avatar
																	sx={{
																		bgcolor: medalColors[index],
																		color: "#18181b",
																		mx: "auto",
																		width: 24,
																		height: 24,
																		fontSize: "1rem",
																		fontWeight: "600",
																	}}
																>
																	{index + 1}
																</Avatar>
																<Box
																	sx={{
																		height: "calc(100% - 16px)",
																		display: "flex",
																		flexDirection: "column",
																		alignItems: "center",
																		justifyContent: "flex-end",
																	}}
																>
																	<Box
																		sx={{
																			writingMode: "vertical-rl",
																			transform: "rotate(180deg)",
																			margin: "16px",
																		}}
																	>
																		<Typography
																			fontSize="small"
																			component="p"
																			textAlign="left"
																			fontWeight="800"
																			noWrap
																		>
																			{
																				props.topAthletes[topic][
																					index
																				].athlete.split(" ")[0]
																			}
																		</Typography>
																		<Typography
																			fontSize="small"
																			component="p"
																			textAlign="left"
																			fontWeight="800"
																			noWrap
																		>
																			{props.topAthletes[topic][index].athlete
																				.split(" ")
																				.at(-1)}
																		</Typography>
																	</Box>
																	<Typography
																		variant="overline"
																		textAlign="center"
																		fontWeight="600"
																		color="primary"
																	>
																		{formatAmount(
																			props.topAthletes[topic][index].amount,
																			topic
																		)}
																	</Typography>
																</Box>
															</CardContent>
														</Card>
													</CardContent>
												</CardActionArea>
											</Card>
										</Grid>
									))}
								</Grid>
								{props.topAthletes[topic].slice(3).map((row, index) => (
									<Card key={index} sx={{ mb: 1 }}>
										<CardActionArea
											onClick={() => row.id > -1 && displayCard(row.id)}
										>
											<CardContent
												sx={{
													p: 1,
													"&:last-child": { pb: 1 },
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
											>
												<Box
													style={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
														flex: "0 1 auto",
														overflow: "hidden",
														textOverflow: "ellipsis",
													}}
												>
													<Avatar
														alt={row.athlete}
														src={retrieveAvatar(index + 3)}
														sx={{ mr: 2, width: 32, height: 32 }}
													/>
													<Typography variant="body1" fontWeight="600" noWrap>
														{row.athlete}
													</Typography>
												</Box>
												<Typography
													variant="body2"
													fontWeight="600"
													sx={{ flex: "0 0 auto" }}
												>
													{formatAmount(row.amount, topic)}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								))}
							</CardContent>
						) : (
							<ChallengesLeaderboard
								allChallenges={props.topAthletes[topic]}
								default={props.topAthletes[topic].filter((ch) => ch.mn)[0].id}
								dimensions={props.dimensions}
								displayCard={displayCard}
							/>
						)}
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card className="animate-content-card-up">
						<CardHeader
							title={
								<Typography component="h2" fontWeight="bold">
									Search Athletes
								</Typography>
							}
						/>
						<CardContent>
							<Autocomplete
								fullWidth
								autoHighlight
								disableClearable
								noOptionsText="No matches"
								sx={{ mb: 2.75 }}
								options={matches}
								getOptionLabel={(option) => {
									let result = "";
									if (searched.length > 2) {
										if (
											option.stravaName
												.toUpperCase()
												.includes(searched.toUpperCase())
										) {
											result = option.stravaName;
										} else {
											if (
												option.deloitteName
													.toUpperCase()
													.includes(searched.toUpperCase())
											) {
												result = option.deloitteName;
											} else {
												result = option.email;
											}
										}
									}
									return result;
								}}
								renderOption={(props, option) => (
									<Box component="li" {...props}>
										<span>
											<span
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "left",
												}}
											>
												<img
													src="img/Strava_Circle.png"
													alt="Strava logo"
													width="16px"
													height="16px"
												/>
												<Typography
													variant="caption"
													sx={{ fontWeight: "bold", ml: 2 }}
												>
													{option.stravaName}
												</Typography>
											</span>
											<span
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "left",
												}}
											>
												<img
													src="img/Deloitte_D_Circle.png"
													alt="Deloitte logo"
													width="16px"
													height="16px"
													style={{
														border: "2px solid #86BC25",
														borderRadius: "50%",
													}}
												/>
												<Typography
													variant="caption"
													sx={{ fontWeight: "normal", ml: 2 }}
												>
													{option.deloitteName.length > 0
														? option.deloitteName
														: "No name available"}
												</Typography>
												<Typography
													variant="caption"
													sx={{ fontWeight: "normal", ml: 2 }}
												>
													(
													{option.email.length > 0
														? option.email
														: "no email available"}
													)
												</Typography>
											</span>
										</span>
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Athlete name, email, or Strava name"
										inputProps={{
											...params.inputProps,
											autoComplete: "new-password",
										}}
									/>
								)}
								inputValue={searched}
								value={chosen}
								onInputChange={handleSearchTermChange}
								onChange={(_event, value) => {
									setChosen(value);
									displayCard(value.id);
								}}
							/>
							{loading ? (
								<Skeleton variant="rounded" height={320} ref={ref} />
							) : athlete ? (
								<AthleteProfileCard
									athlete={athlete}
									dimensions={props.dimensions}
									format={formatAmount}
									close={closeProfileCard}
								/>
							) : (
								<Card sx={{ borderStyle: "dashed" }}>
									<CardContent>
										<Typography
											variant="h6"
											textAlign="center"
											fontWeight="600"
										>
											No athlete selected.
										</Typography>
										<Typography variant="body2" textAlign="center">
											Select an athlete from the leaderboard, or search for an
											athlete by name, Deloitte email, or Strava name.
										</Typography>
									</CardContent>
								</Card>
							)}
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Container>
	);
};

export default LeaderboardPage;
