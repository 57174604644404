import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import App from "./App.js";
import { DeloitteLogin, SaveLoginData, Registration } from "./components";
import { AdminPage, PublishPage, LogoutPage } from "./pages";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme } from "./DarkTheme.js";
import { lightTheme } from "./LightTheme.js";

const defaultCookieValue = "ZUJXRaI3b6auo2p3xZ9p3w==";

class AppRouter extends React.Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired,
	};

	constructor(props) {
		super(props);
		const { cookies } = props;
		this.state = {
			cookie: cookies.get('ddchallenge-session') || defaultCookieValue
		};
		this.toggleTheme = this.toggleTheme.bind(this);
	}

	componentDidMount() {
		let storedIsDarkTheme = localStorage.getItem("isDarkTheme");
		let isDarkTheme = storedIsDarkTheme
			? storedIsDarkTheme === "true"
			: (window.matchMedia
				? window.matchMedia("(prefers-color-scheme: dark)").matches
				: false
			);
		let tmpCookie = this.state.cookie;
		fetch("/api/checkAthleteAttributes?athleteId=" + tmpCookie)
			.then((resp) => resp.json())
			.then((resp) => {
				if (sessionStorage.getItem("user") !== null) {
					if (tmpCookie !== defaultCookieValue) {
						tmpCookie = defaultCookieValue;
					}
					resp.hasDeloitteEmail = true;
				}
				this.setState({
					isDarkTheme: isDarkTheme,
					cookie: tmpCookie,
					userInformation: resp
				});
			});
	}

	toggleTheme() {
		this.setState({ isDarkTheme: !this.state.isDarkTheme }, () => {
			localStorage.setItem(
				"isDarkTheme",
				this.state.isDarkTheme.toString()
			);
		});
	}

	render() {
		let { cookie, userInformation, isDarkTheme } = this.state;
		if (!userInformation) {
			return null;
		}
		return (
			<ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
				<CssBaseline />
				<Router>
					<Routes>
						<Route
							path="/"
							element={
								userInformation.hasDeloitteEmail ? (
									cookie !== defaultCookieValue ? (
										userInformation.isInClub ? (
											<App
												athleteId={cookie}
												isAdmin={userInformation.isAdmin}
												toggleTheme={this.toggleTheme}
												isDarkTheme={isDarkTheme}
											/>
										) : (
											<Registration
												step={3}
											/>
										)
									) : (
										<Registration
											target={process.env.REACT_APP_TARGET}
											step={2}
										/>
									)
								) : (
									<Registration
										step={1}
										updateProfile={cookie !== defaultCookieValue && userInformation.isInClub}
									/>
								)
							}
						/>
						<Route
							path="/dlogin"
							element={<DeloitteLogin />}
						/>
						<Route
							path="/updateProfile"
							element={<Registration step={1} updateProfile />}
						/>
						<Route
							path="/logout/:code"
							element={<LogoutPage athleteId={cookie} />}
						/>
						<Route
							path="/save"
							element={<SaveLoginData encryptedId={cookie} />}
						/>
						<Route
							path="/admin"
							element={
								userInformation.isAdmin ?
									(process.env.REACT_APP_TARGET === 'www' ?
										<PublishPage />
										:
										<AdminPage />
									)
									:
									<Navigate replace to={"/"} />
							}
						/>
						<Route path="*" element={<Navigate replace to={"/"} />} />
					</Routes>
				</Router>
			</ThemeProvider>
		);
	}
}

export default withCookies(AppRouter);