import React from 'react';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

const DeloitteLogin = () => {
  const { instance } = useMsal();

  useEffect(() => {
    instance
      .loginRedirect()
      .catch((e) => {
        console.error(e);
      });
  }, [instance]);

  return <></>;
};

export default DeloitteLogin;