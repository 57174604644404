import React from 'react';
import { Container, Link, Grid } from '@mui/material';
import stravaSvgSrc from "../img/logo/strava.svg";
import stravaPngSrc from "../img/logo/strava.png";

const Footer = () => {
    return (
        <footer className="footer-container">
					<div className="separator"></div>
					<Container maxWidth="lg">
						<Grid container justifyContent="space-between" alignItems="center">
							<img
								src={stravaPngSrc}
								srcSet={stravaSvgSrc}
								alt="Powered By Strava"
								style={{ maxWidth: "100px" }}
							/>
							<Link
								variant="text"
								target="_blank"
								href="https://cookienotice.deloitte.com"
							>
								Cookies
							</Link>
						</Grid>
					</Container>
				</footer>
    );
};

export default Footer;