import React from 'react';
import { Dialog, DialogContent, Link, Grow } from '@mui/material';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import { Fireworks } from 'fireworks-canvas';

class NotificationAchievement extends React.Component {
    constructor(props) {
        super(props);
        this.state = { modal: false, transitionEnded: true, pendingNotifications: [] };
        this.toggleModal = this.toggleModal.bind(this);
        this.handleExit = this.handleExit.bind(this);
        this.dialogEnter = this.dialogEnter.bind(this);
    }

    dialogEnter() {
        const container = document.getElementById("fireworks-container");
        const options = {
            maxRockets: 12,
            rocketSpawnInterval: 150,
            numParticles: 100,
            explosionMinHeight: 0.5,
            explosionMaxHeight: 0.8,
            explosionChance: 0.04,
        };
        const fireworks = new Fireworks(container, options);
        this.setState({ fireworks: fireworks });
    }

    toggleModal() {
        const { athleteId } = this.props;
        const challengeId = this.state.pendingNotifications[0].challengeId;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                athleteId: athleteId,
                challengeId: challengeId
            })
        };
        fetch('api/updateNotificationStatus', requestOptions);
        this.props.notificationCompleted(challengeId);
        this.setState({
            pendingNotifications: this.state.pendingNotifications.shift(),
            modal: false,
            transitionEnded: false
        });

    }

    handleExit() {
        if (this.state.pendingNotifications.length > 0) {
            this.setState({
                modal: true, transitionEnded: true
            });
        } else {
            this.state.fireworks.stop();
            this.setState({
                modal: false, transitionEnded: true
            });
        }
    }

    static getDerivedStateFromProps(props, unused) {
        return {
            pendingNotifications: props.notifications,
            modal: (props.notifications.length > 0 ? true : false)
        };
    }

    render() {
        let challengeName = (this.state.pendingNotifications.length > 0 ? this.state.pendingNotifications[0].challengeName : "");
        return (
            <div>
                <Dialog
                    open={this.state.modal && this.state.pendingNotifications.length > 0 && this.state.transitionEnded}
                    fullScreen={false}
                    TransitionComponent={Grow}
                    transitionDuration={{ enter: 2000, exit: 100 }}
                    TransitionProps={{
                        onEnter: this.dialogEnter,
                        onExited: this.handleExit,
                        onEntered: () => {this.state.fireworks.start()}
                    }}
                    classes={{
                        paper: 'trophy-dialog'
                    }}
                >
                    <div id="fireworks-container" className="fireworks-container"></div>
                    <DialogContent>
                        <div className="trophy-div">
                            <div className="large-text">Congratulations</div>
                            <EmojiEventsTwoToneIcon style={{ fontSize: 76 }} />
                            <div >You reached your goal for {challengeName}!</div>
                            <div className="last-line">Keep up the pace.</div>
                            <Link
                                component="button"
                                variant="body2"
                                color="inherit"
                                onClick={this.toggleModal}
                            >
                                CLOSE
                            </Link>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default NotificationAchievement;