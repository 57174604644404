import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";

const SaveLoginData = (props: any) => {
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      if (accounts.length > 0) {
        const accessTokenRequest = {
          scopes: ["user.read"],
          account: accounts[0],
        };
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            const accessToken = accessTokenResponse.accessToken;
            let headers = new Headers();
            headers.append("Authorization", "Bearer " + accessToken);
            const options = {
              method: "GET",
              headers: headers
            };
            let graphEndpoint = "https://graph.microsoft.com/v1.0/me";
            fetch(graphEndpoint, options)
              .then(resp => resp.json())
              .then(user => {
                user.encryptedId = props.encryptedId;
                let url = "api/saveDeloitteUser";
                const requestOptions = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(user),
                };
                fetch(url, requestOptions)
                  .then(resp => resp.json())
                  .then(success => {
                    if (!success) {
                      sessionStorage.setItem("user", JSON.stringify(user));
                    } 
                    window.location.replace("/")
                  });
              });
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(accessTokenRequest);
            }
            console.log(error);
          });
      } else {
        console.error("No account detected!");
      }
    }
  }, [instance, inProgress, accounts, props])

  return <></>;
};

export default SaveLoginData;