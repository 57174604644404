import React, { useEffect, useState } from "react";
import {
	Checkbox,
	Container,
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Chip,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { MenuBar } from "../components";
import { enqueueSnackbar } from "notistack";

function PublishPage() {
	const [challenges, setChallenges] = useState([]);
	const [selected, setSelected] = useState([]);
	const [status, setStatus] = useState([]);
	const [submitLoading, setSubmitLoading] = useState(false);

	useEffect(() => {
		fetch("https://qa.ddglobalchallenge.com/api/adPage/loadData", {
			accept: "application/json",
		})
			.then((resp) => resp.json())
			.then((data) => {
				setChallenges(data.challenges);
				setStatus(
					data.challenges.map(() => ({
						status: <Chip label="Draft" variant="outlined" />,
						prodId: null,
					}))
				);
			});
	}, []);

	const handleSelect = (event, id) => {
		setSelected((prevSelected) =>
			event.target.checked
				? [...prevSelected, id]
				: prevSelected.filter((item) => item !== id)
		);
	};

	const showNotification = (notificationText, notificationVariant) => {
		enqueueSnackbar(notificationText, {
			variant: notificationVariant,
		});
	};

	const submitSelected = async () => {
		setSubmitLoading(true);
		const promises = selected.map(async (id) => {
			const challenge = challenges.find((c) => c.id === id);
			const index = challenges.indexOf(challenge);
			const formData = new FormData();
			formData.append(
				"jsonString",
				JSON.stringify({
					id: -1,
					name: challenge.name,
					description: challenge.description,
					amount: challenge.amount,
					dimensionId: challenge.dimensionId,
					startDate: challenge.startDate,
					endDate: challenge.endDate,
					isSingle: challenge.isSingle,
					isTeam: challenge.isTeam,
					categories: challenge.categories,
					isDeloitteAttribute: challenge.isDeloitteAttribute,
					attribute: challenge.attribute,
					newImage: true,
					imagename: challenge.image,
				})
			);

			setStatus((prevStatus) => {
				const newStatus = [...prevStatus];
				newStatus[index] = {
					status: <CircularProgress size={20} />,
					prodId: <Skeleton animation="wave" />,
				};
				return newStatus;
			});

			const imageResponse = await fetch(
				"https://qa.ddglobalchallenge.com/img/" + challenge.image
			);
			const blob = await imageResponse.blob();
			formData.append("file", blob);

			const requestOptions = {
				method: "POST",
				body: formData,
			};

			const response = await fetch(
				"/api/adPage/createChallenge",
				requestOptions
			).then((resp) => resp.json());

			switch (response) {
				case 0:
					setStatus((prevStatus) => {
						const newStatus = [...prevStatus];
						newStatus[index] = {
							status: <Chip label="Error" color="error" variant="outlined" />,
							prodId: null,
						};
						return newStatus;
					});
					showNotification(
						`Error parsing data for challenge ${challenge.name}`,
						"error"
					);
					break;
				case -1:
					setStatus((prevStatus) => {
						const newStatus = [...prevStatus];
						newStatus[index] = {
							status: <Chip label="Error" color="error" variant="outlined" />,
							prodId: null,
						};
						return newStatus;
					});
					showNotification(
						`Database error creating or modifying challenge ${challenge.name}`,
						"error"
					);
					break;
				default:
					setStatus((prevStatus) => {
						const newStatus = [...prevStatus];
						newStatus[index] = {
							status: (
								<Chip label="Published" color="success" variant="outlined" />
							),
							prodId: response,
						};
						return newStatus;
					});
					await fetch(
						"https://qa.ddglobalchallenge.com/api/adPage/publishChallenge/" +
							id,
						{ method: "POST" }
					);
					setSelected((prevSelected) =>
						prevSelected.filter((item) => item !== id)
					);
					break;
			}
		});

		await Promise.all(promises);
		setSubmitLoading(false);
	};

	return (
		<>
			<MenuBar />
			<Container sx={{ my: 2 }} maxWidth="lg">
				<Typography component="h1" variant="h5">
					Publish Challenges
				</Typography>
				<Typography component="p" variant="body1" sx={{ mb: 2 }}>
					Select unpublished challenges from the QA environment that you want to
					push to PRODUCTION and click the "Publish" button.
				</Typography>
				<Card className="animate-content-card-up">
					<CardContent>
						<CardHeader
							action={
								<Tooltip
									placement="top"
									title={
										!submitLoading &&
										(selected.length === 0 ||
											!challenges.some(
												(challenge, i) =>
													selected.includes(challenge.id) &&
													status[i].prodId === null
											)) &&
										"Select at least one challenge"
									}
								>
									<span>
										<LoadingButton
											variant="contained"
											color="primary"
											loading={submitLoading}
											loadingPosition="start"
											startIcon={<CloudUploadIcon />}
											onClick={submitSelected}
											disabled={
												!submitLoading &&
												(selected.length === 0 ||
													!challenges.some(
														(challenge, i) =>
															selected.includes(challenge.id) &&
															status[i].prodId === null
													))
											}
											sx={{ mt: 2 }}
										>
											Publish
										</LoadingButton>
									</span>
								</Tooltip>
							}
							title={
								<Typography component="h2" variant="h6">
									All Challenges
								</Typography>
							}
							subheader={challenges.length + " items"}
						/>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>
										<Checkbox
											indeterminate={
												selected.length > 0 &&
												selected.length < challenges.length
											}
											checked={
												challenges.length > 0 &&
												selected.length === challenges.length
											}
											onChange={(ev) =>
												setSelected(
													ev.target.checked ? challenges.map((n) => n.id) : []
												)
											}
											inputProps={{ "aria-label": "select all challenges" }}
											disabled={
												submitLoading ||
												(selected.length === challenges.length &&
													status.every((s) => s.prodId !== null))
											}
										/>
									</TableCell>
									<TableCell align="left">Challenge Name</TableCell>
									<TableCell align="center">Status</TableCell>
									<TableCell align="center">Prod ID</TableCell>
								</TableRow>
							</TableHead>
							<TableBody sx={{ "td, tr": { border: 0 } }}>
								{challenges.map((challenge, i) => (
									<TableRow key={challenge.id} sx={{ border: 0 }}>
										<TableCell>
											<Checkbox
												checked={selected.includes(challenge.id)}
												onChange={(ev) => handleSelect(ev, challenge.id)}
												disabled={status[i].prodId !== null}
											/>
										</TableCell>
										<TableCell align="left">{challenge.name}</TableCell>
										<TableCell align="center">{status[i].status}</TableCell>
										<TableCell align="center">{status[i].prodId}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</CardContent>
				</Card>
			</Container>
		</>
	);
}

export default PublishPage;
