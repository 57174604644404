import React from "react";
import {
	Grid,
	Chip,
	Card,
	CardContent,
	CardHeader,
	Typography
} from "@mui/material";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import Brightness3OutlinedIcon from "@mui/icons-material/Brightness3Outlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";

const coolFactsIconsStyle = { color: "#888", fontSize: 20 };
const coolFactsIcons = [
	<PublicOutlinedIcon style={coolFactsIconsStyle} />,
	<Brightness3OutlinedIcon style={coolFactsIconsStyle} />,
	<AccessTimeOutlinedIcon style={coolFactsIconsStyle} />,
	<LandscapeOutlinedIcon style={coolFactsIconsStyle} />,
];

export default function GlobalFacts(props) {
	let values = props.totals;

	// Declare constants
	const circumferenceOfEarth = 24901;
	const distanceToMoon = 238900;
	const heightOfOlympusMons = 72000;

	// Calculate results
	const years = Math.floor(values.Duration / 8766);
	const months = Math.floor((values.Duration % 8766) / 730.5);
	const days = Math.floor((values.Duration % 168) / 24);
	const hours = Math.floor(values.Duration % 24);

	// Prepare results sentences
	const timesAroundEarth = `Went ${Math.round((values.Distance * 100) / circumferenceOfEarth) / 100
		} times around Earth`;
	const distanceOfMoonPercentage = `Traveled ${Math.round(
		(values.Distance * 100) / distanceToMoon
	)}% to the Moon`;
	const durationResults = `Exercised ${years} year${years === 1 ? ", " : "s, "
		}${months} month${months === 1 ? ", " : "s, "}${days} day${days === 1 ? " and " : "s and "
		}${hours} hour${hours === 1 ? "" : "s"}`;
	const timeTopMtOlympus = `Climbed Olympus Mons ${Math.round(
		values.Elevation / heightOfOlympusMons
	)} times`;

	// Return results
	let coolFacts = [
		timesAroundEarth,
		distanceOfMoonPercentage,
		durationResults,
		timeTopMtOlympus,
	];

	return (
		<Card className="animate-content-card-up">
			<CardHeader
				title={
					<Typography component="h2" fontWeight="bold">
						Cool Facts
					</Typography>
				}
			></CardHeader>
			<CardContent sx={{ pt: 0 }}>
				<Grid
					container
					spacing={1}
				>
					{coolFacts.map((fact, index) => (
						<Grid item key={index}>
							<Chip icon={coolFactsIcons[index]} label={fact} />
						</Grid>
					))}
				</Grid>
			</CardContent>
		</Card>
	);
}
