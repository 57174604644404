import { ButtonGroup, Button, Tooltip, Typography } from "@mui/material";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { HeatmapLayerFactory } from "@vgrid/react-leaflet-heatmap-layer";
import Control from "react-leaflet-custom-control";
import "leaflet/dist/leaflet.css";
import React from "react";
import L from "leaflet";

const posIcon = L.icon({ iconUrl: "../img/logo/small-green-ball.png", iconSize: 5 });
const controller = new AbortController()
const HeatmapLayer = HeatmapLayerFactory(L);

class HeatMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allHeatmapPoints: [],
            heatmapPoints: [],
            center: [0, 0],
            jobTitle: [],
            precision: 0,
            window: 0,
            zoom: 1,
        };
        this.handleChangeLevels = this.handleChangeLevels.bind(this);
		this.handleChangeWindow = this.handleChangeWindow.bind(this);
        this.isDarkTheme = props.isDarkTheme;
        this.allLevels = props.allLevels;
        this.dayMarks = props.dayMarks;
    }

    componentDidMount() {
        this.setState({
            jobTitle: this.allLevels.map(level => level.id),
            window: this.dayMarks[0].value,
        })

        this.getHeatmapData({
            jobTitle: this.allLevels.map(level => level.id),
            precision: this.state.precision,
            window: this.dayMarks[0].value,
            zoom: this.state.zoom,
            firstCall: true,
        })
    }

    getHeatmapData({
        precision, 
        firstCall,
        jobTitle, 
        window, 
    }) {

        let params = {
            utcOffset: new Date().getTimezoneOffset(),
            window: this.dayMarks[window].submitValue,
            precision: precision,
            titleIds: jobTitle,
        };

        fetch("/api/getHeatmapData?" + this.encodeParams(params), {
            method: "get",
            signal: controller.signal,
            accept: "application/json"
        })
        .then(resp => resp.json())
        .then(data => {
            this.setMapPoints({
                centerArray: [data.centerX, data.centerY],
                mapPointsArray: data.heatMapPoints,
                setAllPoints: firstCall,
                zoomLevel: data.zoom,
            })
        })
        .catch((err) => {
            console.log(err);
        })    
    }

    setMapPoints({
        mapPointsArray,
        setAllPoints,
        centerArray,
        zoomLevel,
    }) {
        let { map } = this.state;

        if(setAllPoints) {
            this.setState({
                allHeatmapPoints: mapPointsArray,
                heatMapPoints: mapPointsArray,
                center: centerArray,
                zoom: zoomLevel,
            },
                () => map.flyTo(centerArray, zoomLevel)
            );
        } else {
            this.setState({
                allHeatmapPoints: mapPointsArray,
            },
                () => map.setView(map.getCenter(), map.getZoom())
            );
        }
    }

    updateMapView(centerArray, zoomLevel) {
        let { map } = this.state;
        let needToFly = map && (map.getCenter().lat !== centerArray[0] || map.getCenter().lng !== centerArray[1]);

        if(this.state.shouldFlyOut && needToFly) map.flyTo(centerArray, zoomLevel);
        else map.setView(map.getCenter(), map.getZoom());
    }

    registerMapEvents(map) {
        map.on("zoomend", () => {
            if (map.getZoom() % 4 === 0) {
                this.setState({
                    precision: map.getZoom() / 4,
                    zoom: map.getZoom(),
                });

                this.getHeatmapData({
                    jobTitle: this.state.jobTitle,
                    precision: map.getZoom() / 4,
                    window: this.state.window,
                    zoom:map.getZoom(),
                    firstCall: false,
                })
            }
        });
        map.on("movestart", () => {
            this.setState({ heatmapPoints: [] });
        });
        map.on("moveend", () => {
            let { allHeatmapPoints } = this.state;
            let visiblePoints = [];
            allHeatmapPoints.forEach(point => {
                if (map.getBounds().contains([point.lat, point.lon])) {
                    visiblePoints.push(point);
                }
            });
            if (visiblePoints.length > 0) {
                this.setState({ 
                    heatmapPoints: visiblePoints
                });
            }
        });
    }

    handleChangeLevels(id) {
        let { jobTitle } = this.state;

        if (jobTitle.indexOf(id) < 0) {
            jobTitle.push(id);
        } else {
            jobTitle.splice(jobTitle.indexOf(id), 1);
        }

        this.setState({
            jobTitle: jobTitle
        })

        if(jobTitle.length === 0) {
            this.setMapPoints({
                centerArray: this.state.center,
                mapPointsArray: [],
                setAllPoints: true,
                zoomLevel: this.state.zoom,
            })
        } else {
            this.getHeatmapData({
                precision: this.state.precision,
                window: this.state.window,
                zoom: this.state.zoom,
                jobTitle: jobTitle,
                firstCall: true,
            })    
        }
    }

	handleChangeWindow(newWindow) {
        this.setState({
            window: newWindow
        })

        if(this.state.jobTitle.length !== 0) {
            this.getHeatmapData({
                precision: this.state.precision,
                jobTitle: this.state.jobTitle,
                zoom: this.state.zoom,
                window: newWindow,
                firstCall: true,
            })    
        }
	}

    encodeParams (data) {
        const result = [];
        for (let param in data) {
            if (data[param] !== null) {
                result.push(encodeURIComponent(param) + "=" + encodeURIComponent(data[param]));
            }
        }
        return result.join("&");
    }

    convertTitle(title) {
        let response = title.split(/[//]/);
        if (response.length > 1) {
            let combined = [];
            response.forEach(resp => combined.push(resp.split(/[\s]/).map(part => part.substring(0,1)).join("")));
            response = combined.join("/");
        } else {
            response = title.split(/[\s]/).map(part => part.substring(0,1)).join("");
        }
        return response;
    }

    render() {
        let { isDarkTheme, allLevels, dayMarks } = this.props;
        let { heatmapPoints, center, zoom, jobTitle, window } = this.state;
        let bounds = [[-90, -180], [90, 180]];
        return (
            <MapContainer
                style={{ backgroundColor: isDarkTheme ? "#7a7a7a" : "#ccc" }}
                center={center} 
                zoom={zoom}
                minZoom={1} 
                maxZoom={18}
                scrollWheelZoom 
                attributionControl 
                tap={false}
                whenReady={map => this.setState({ map: map.target }, this.registerMapEvents(map.target))}
            >
                <HeatmapLayer
                    points={heatmapPoints}
                    latitudeExtractor={m => m.lat}
                    longitudeExtractor={m => m.lon}
                    intensityExtractor={m => m.intensity}
                    max={1.0}
                    radius={35}
                    gradient={{ 0.2: "blue", 0.4: "green", 0.6: "yellow", 0.8: "orange", 1.0: "red" }}
                    minOpacity={0.4}
                />
                {heatmapPoints.map((point, i) =>
                    <Marker key={i} position={[point.lat, point.lon]} icon={posIcon} />
                )}
                <Control position="topright">
                    <ButtonGroup orientation="vertical" variant="contained">
                        {allLevels.map(level =>
                            <Tooltip key={level.id} placement="left" title={level.description}>
                                <Button
                                    color={jobTitle.indexOf(level.id) > -1 ? "primary" : "inherit"}
                                    onClick={() => this.handleChangeLevels(level.id)}
                                    variant="contained"
                                    size="small"
                                    sx={jobTitle.indexOf(level.id) < 0 && { backgroundColor: '#e0e0e0'}}
                                >
                                    {this.convertTitle(level.description)}
                                </Button>
                            </Tooltip>
                        )}
                    </ButtonGroup>
                </Control>
                <Control position="bottomleft">
                    <Typography variant="overline" display="block" sx={{ mb: 1 }}>
                        Include Previous Days
                    </Typography>
                    <ButtonGroup orientation="horizontal" variant="contained">
                        {dayMarks.map(mark =>
                            <Button
                                key={mark.value}
                                color={mark.value === window ? "primary" : "inherit"}
                                onClick={() => this.handleChangeWindow(mark.value)}
                                variant="contained"
                                size="small"
                                sx={mark.value !== window && { backgroundColor: '#e0e0e0'}}
                            >
                                {mark.label}
                            </Button>
                        )}
                    </ButtonGroup>
                </Control>
                <TileLayer
                    className={isDarkTheme ? "leaflet-tile-pane-dark" : "leaflet-tile-pane"}
                    attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    maxBounds={bounds}
                    noWrap
                />
            </MapContainer>
        );
    }
}

export default HeatMap;
    