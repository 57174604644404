import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: '7a276aa7-0adb-47d0-883a-b6c0c51adbe8', 
    authority: 'https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921',
    redirectUri: '/save', 
    postLogoutRedirectUri: '/', 
    navigateToLoginRequestUrl: false, 
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
          case LogLevel.Warning:
            console.error(message);
            break;
          default:
            break;
        }
        return;
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [],
};