import React from "react";
import { Card, CardHeader, Container, Typography } from "@mui/material";
import HeatMap from "../components/HeatMap";

const dayValues = [7, 14, 30, 180];

class HeatmapPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dayMarks: [],
			allLevels: [],
		};
	}

	componentDidMount() {
		let today = this.dayOfYear();
		let pos = 0;
		let newDayMarks = [];
		dayValues.forEach((mark, i) => {
			if (mark >= today && pos === 0) {
				newDayMarks.push({
					value: i,
					label: "this year",
					submitValue: null,
				});
				pos = 1;
			}
			newDayMarks.push({
				value: i + pos,
				label: mark,
				submitValue: mark,
			});
		});
		if (pos === 0) {
			newDayMarks.push({
				value: dayValues.length,
				label: "this year",
				submitValue: null,
			});
		}
		fetch("api/getNormalizedTitles", { accept: "application/json" })
			.then((resp) => resp.json())
			.then((data) => {
				this.setState({
					dayMarks: newDayMarks,
					allLevels: data.sort((a, b) => {
						return a.sort - b.sort;
					}),
				});
			});
	}

	dayOfYear() {
		let now = new Date();
		let start = new Date(now.getFullYear(), 0, 0);
		let diff =
			now -
			start +
			(start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
		let oneDay = 1000 * 60 * 60 * 24;
		return Math.floor(diff / oneDay);
	}

	render() {
		let { dayMarks, allLevels } = this.state;
		let { isDarkTheme } = this.props;
		if (dayMarks.length === 0 || allLevels.length === 0) return null;
		return (
			<Container maxWidth="md" sx={{ my: 5 }}>
				<Card className="animate-content-card-up">
					<CardHeader
						title={
							<Typography component="h2" fontWeight="bold">
								Activity Heatmap
							</Typography>
						}
						subheader={
							<Typography variant="caption">
								Global activity map based on the density of starting points for
								the parameters selected; it includes only those activities with
								a defined latitude and longitude.
							</Typography>
						}
					/>
					<HeatMap
						isDarkTheme={isDarkTheme}
						allLevels={allLevels}
						dayMarks={dayMarks}
					/>
				</Card>
			</Container>
		);
	}
}

export default HeatmapPage;
