import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	Container,
	Typography,
} from "@mui/material";

const LogoutPage = (props) => {
	const { code } = useParams();
	const [message, setMessage] = useState([]);

	const redirectHome = () => {
		window.location.href = "/";
	};

	useEffect(() => {
		if (code && props.athleteId) {
			const requestOptions = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					athleteId: props.athleteId,
					deleteCode: code,
				}),
			};
			fetch("/api/removeAthlete", requestOptions)
				.then((resp) => resp.json())
				.then((resp) => {
					document.cookie =
						"ddchallenge-session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.ddglobalchallenge.com";
					setMessage(resp.description.split("+"));
				});
		}
	}, [code, props.athleteId]);

	return (
		<Container
			maxWidth="none"
			sx={{ height: "100vh", padding: "0 !important" }}
		>
			<div className="bg-container">
				<div className="bg-section">
					<div className="bg-overlay"></div>
				</div>
				<div className="bg-section">
					<div className="bg-overlay"></div>
				</div>
				<div className="bg-section">
					<div className="bg-overlay"></div>
				</div>
				<div className="bg-section">
					<div className="bg-overlay"></div>
				</div>
			</div>
			<Card className="login-box" sx={{ maxWidth: 400, p: 2 }}>
				<CardContent>
					<Box>
						<Alert variant="outlined" severity="success">
							<Typography component="h2">{message[0]}</Typography>
							<Typography variant="body2">{message[1]}</Typography>
							{code === 2 && (
								<Typography variant="body2">
									In addition, your data has been removed from our application.
								</Typography>
							)}
							<Button
								sx={{ mt: 1 }}
								onClick={redirectHome}
								variant="contained"
								color="secondary"
							>
								Rejoin the challenge
							</Button>
						</Alert>
					</Box>
				</CardContent>
			</Card>
		</Container>
	);
};

export default LogoutPage;
