import React from 'react';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Chip, Grid, Typography } from '@mui/material';

class PastAchievements extends React.Component {
    render() {
        const { challenges } = this.props;

        return (
            <>
                {challenges.length === 0 ? (
                    <Typography variant="caption">
                        No achievements earned. Complete challenges to earn achievements.&nbsp;
                    </Typography>
                ) : (
                    <Grid container sx={{ my: 2 }} spacing={1}>
                        {challenges.map((ch, id) => (
                            <Grid item key={id}>
                                <Chip
                                    size="small"
                                    icon={<EmojiEventsIcon style={{ color: '#F2B256' }} />}
                                    label={ch.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </>
        );
    }
}

export default PastAchievements;