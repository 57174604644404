import React from 'react';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import ChallengeCard from './ChallengeCard';
import { Typography } from '@mui/material';

class ChallengeList extends React.Component {
    render() {
        let { challenges, athleteId, onSignUp, dimensions, myTeam, myProfile } = this.props;
        let displayChallenges = challenges.map((ch, i) => {
            let challengeData;
            switch (typeof ch.achieved) {
                case 'undefined':
                    challengeData = {
                        achieved: false,
                        challengeId: ch.challenge.id,
                        challengeName: ch.challenge.name,
                        challengeType: ch.challengeType,
                        description: ch.challenge.description,
                        disciplines: ch.disciplines,
                        endDate: ch.challenge.endDate,
                        enrolledAthleteCount: ch.enrolledAthleteCount,
                        image: ch.challenge.image,
                        isMain: ch.challenge.main,
                        isSingle: ch.challenge.isSingle,
                        isTeam: ch.challenge.isTeam,
                        notified: false,
                        progressPercentage: 0,
                        remainingDays: '',
                        startDate: ch.challenge.startDate,
                        totalAchieved: 0,
                        totalGoal: ch.challenge.amount,
                        isFuture: ch.isFuture,
                        signedUp: ch.signedUp,
                        updateParticipants: ch.updateParticipants,
                        isDeloitteAttribute: ch.challenge.isDeloitteAttribute,
                        attribute: ch.challenge.attribute
                    }
                    break;
                default:
                    challengeData = ch;
                    challengeData.isFuture = false;
                    challengeData.signedUp = true;
            }
            let theTeam;
            if (challengeData.isDeloitteAttribute) {
                theTeam = {
                    id: challengeData.attribute === 'T' ? myProfile.normalizedTitleId : myProfile.officeId,
                    name: challengeData.attribute === 'T' ? myProfile.normalizedTitle : myProfile.office
                };
            } else {
                theTeam = myTeam;
            }
            return (
                <Grid item key={i} xs={12}>
                    <ChallengeCard 
                        sx={{ height:"100%" }} 
                        challengeData={challengeData} 
                        athleteId={athleteId}
                        onSignUp={onSignUp} 
                        dimensions={dimensions}
                        myTeam={theTeam}
                    />
                </Grid>
            )
        }).sort((a,b) => 
            moment(a.props.children.props.challengeData.startDate, 'll').toDate() > moment(b.props.children.props.challengeData.startDate, 'll').toDate()
            ? 1
            : (a.props.children.props.challengeData.startDate === b.props.children.props.challengeData.startDate 
                ? 
                (a.props.children.props.challengeData.challengeName > b.props.children.props.challengeData.challengeName 
                    ? 1 
                    : -1)
                : -1
            )
        );
        return (
            <>
                <Grid container spacing={2}>
                    {displayChallenges.length > 0 
                        ? displayChallenges 
                        : 
                        <Typography align='center' sx={{ mt: 6, ml: 4, mr: 2, mb: 1, fontSize: '16px' }}>
                            The Team is working hard on building new challenges! <br /><br />
                            Get ready to sweat and feel the burn; check back periodically for some next-level challenges you don't want to miss.
                        </Typography>
                    }
                </Grid>
            </>
        )
    }
}

export default ChallengeList;