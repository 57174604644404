import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Card,
    CardHeader,
	CardContent,
	Typography,
	Paper,
} from "@mui/material";

export default function GlobalData(props) {

	const formatAmount = (value, dimension) => {
		let response = "";
		let hasUnit = ["Trophies", "Activities"].indexOf(dimension) < 0;
		let unitShort = hasUnit
			? props.dimensions.find((dim) => dim.name === dimension).unitShort
			: "";
		if (dimension === "Duration") {
			const mins = parseInt((value * 60) % 60);
			response = `${parseInt(value).toLocaleString("en-US")} ${unitShort} ${mins
				.toString()
				.padStart(2, "0")} min`;
		} else {
			response = `${Math.round(value).toLocaleString("en-US")} ${unitShort}`;
		}
		return response;
	};

	return (
		<Card className="animate-content-card-up">
			<CardHeader
				title={
					<Typography component="h2" fontWeight="bold">
						Year-on-Year Comparison
					</Typography>
				}
			/>
			{
				<>
					<CardContent sx={{ py: 0 }}>
						<Typography variant="caption">
							Aggregate exercise statistics from{" "}
							<strong>{props.athleteCount.registered}</strong> registered participants out of the{" "}
							<strong>{props.athleteCount.clubMembers}</strong> club members in Strava
						</Typography>
					</CardContent>
					<Paper
						sx={{
							width: "100%",
							overflow: "hidden",
							marginTop: "16px",
						}}
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table" size="small">
								<TableHead>
									<TableRow>
										<TableCell
											align="left"
										>
											Total
										</TableCell>
										<TableCell
											align="right"
										>
											This Year to Date
										</TableCell>
										<TableCell
											align="right"
										>
											Last Year to Date
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.keys(props.totals).map((dimension, i) => (
										<TableRow
											key={i}
											sx={{
												"&:last-child td, &:last-child th": {
													border: 0,
												},
											}}
										>
											<TableCell align="left">{dimension}</TableCell>
											<TableCell align="right">
												{formatAmount(props.totals[dimension], dimension)}
											</TableCell>
											<TableCell align="right">
												{formatAmount(props.lastYear[dimension], dimension)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</>
			}
		</Card>
	);
}
