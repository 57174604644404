import React from "react";
import {
	Avatar,
	Box,
	Chip,
	IconButton,
	Menu,
	Typography,
	MenuItem,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
	Alert,
	Divider,
	ListItemIcon,
	ListItemText,
	Switch,
	Link as ExternalLink
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CreateIcon from "@mui/icons-material/Create";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LogoutIcon from "@mui/icons-material/Logout";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import LightModeIcon from "@mui/icons-material/LightMode";
import CookieIcon from '@mui/icons-material/Cookie';
import PersonIcon from '@mui/icons-material/Person';

const menuOptions = [
	"Dark Theme",
	"Log Out",
	"Quit Challenge",
	"Forget Me",
];
const menuIcons = [
	<LightModeIcon fontSize="small" />,
	<LogoutIcon fontSize="small" />,
	<HighlightOffIcon fontSize="small" />,
	<CloudOffIcon fontSize="small" />,
];

class UserMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			anchor: null,
			confirmChoice: false
		};
		this.handleClick = this.handleClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleLogOut = this.handleLogOut.bind(this);
		this.confirmationClose = this.confirmationClose.bind(this);
		this.closeMenuAndToggletheme = this.closeMenuAndToggletheme.bind(this);
	}

	handleClick(event) {
		this.setState({ anchor: event.currentTarget });
	}

	handleClose() {
		this.setState({ anchor: null });
	}

	handleLogOut(code) {
		if (code > 0) {
			this.setState({
				anchor: null,
				confirmChoice: true,
				optionSelected: code,
			});
		} else {
			this.executeSelectedAction(code);
		}
	}

	confirmationClose(confirm) {
		if (confirm) {
			this.executeSelectedAction(this.state.optionSelected);
		}
		this.setState({ confirmChoice: false });
	}

	executeSelectedAction(code) {
		window.location.href = '/logout/' + code;
	}

	closeMenuAndToggletheme() {
		this.setState({ anchor: null }, this.props.toggleTheme);
	}

	handleUpdateProfile() {
		window.location.href = "/updateProfile";
	}

	render() {
		let {
			optionSelected,
			anchor,
			confirmChoice
		} = this.state;
		let {
			athleteImageSrc,
			isAdmin,
			isDarkTheme,
			athleteFirstName,
			athleteLastName,
			athleteOffice,
			athleteTitle,
		} = this.props;
		let mainMessage =
			optionSelected === 1
				? "Proceeding will mark you as inactive. We will stop synchronizing your data, but your history will be saved. To return to the challenge, reconnect your account via Strava and we will refresh your data."
				: "Proceeding will delete all your data from this application. To return to the challenge, reconnect your account via Strava and we will reload your activities; however, all previous challenge accomplishments will be lost.";
		let buttonText =
			optionSelected === 1 ? "Yes, Quit Challenge" : "Yes, Delete All My Data";
		let verb = process.env.REACT_APP_TARGET === "www" ? "Publish" : "Create";
		return (
			<>
				<IconButton disableTouchRipple onClick={(ev) => this.handleClick(ev)}>
					<Avatar
						src={
							athleteImageSrc
								? athleteImageSrc
								: "https://d3nn82uaxijpm6.cloudfront.net/assets/avatar/athlete/large-800a7033cc92b2a5548399e26b1ef42414dd1a9cb13b99454222d38d58fd28ef.png"
						}
					/>
					<MoreVertIcon sx={{ color: "white!important" }} />
				</IconButton>
				<Menu
					anchorEl={anchor}
					keepMounted
					open={Boolean(anchor)}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					onClose={this.handleClose}
				>
					<Box key={-3} sx={{ p: 2, pb: 0 }} color="primary">
						<Typography
							variant="body2"
							display="block"
							fontWeight="bold"
							component="span"
							mb={0.5}
						>
							{athleteFirstName} {athleteLastName}
						</Typography>
						<Chip size="small" label={athleteOffice} sx={{ mr: 0.5, mb: 0.5 }} />
						<Chip size="small" label={athleteTitle} sx={{ mb: 0.5 }}/>
						<MenuItem key={-2} onClick={this.handleUpdateProfile} sx={{ pl: 0 }}>
							<ListItemIcon>
								<PersonIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText>Update Profile</ListItemText>
						</MenuItem>
					</Box>
					<Divider key={-2} sx={{mb:1}}/>
					{isAdmin && (
						[
							<MenuItem key={-1} component={RouterLink} to="/admin">
								<ListItemIcon>
									<CreateIcon fontSize="small" />
								</ListItemIcon>
								<ListItemText>{verb} Challenges</ListItemText>
							</MenuItem>,
							<Divider key={'-1d'} />
						]
					)}
					{menuOptions.map((option, i) => [
						<MenuItem
							key={i}
							onClick={i > 0 ? () => this.handleLogOut(i - 1) : null}
						>
							<ListItemIcon>{menuIcons[i]}</ListItemIcon>
							<ListItemText>{option}</ListItemText>
							{i === 0 && (
								<Switch
									checked={isDarkTheme}
									onChange={this.closeMenuAndToggletheme}
									inputProps={{ "aria-label": "Dark mode switch" }}
									size="small"
									name="darkThemeSwitch"
								/>
							)}
						</MenuItem>,
						i === 1 && <Divider key={i+'d'} />
					])}
					<Divider key={10} />
					<MenuItem
						key={11}
						component={ExternalLink}
						href="#"
						className="optanon-toggle-display"
					>
						<ListItemIcon>
							<CookieIcon />
						</ListItemIcon>
						<ListItemText>
							Cookie Settings
						</ListItemText>
					</MenuItem>
				</Menu>
				<Dialog open={confirmChoice} fullScreen={false}>
					<DialogTitle disableTypography="true">Are you sure?</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<Alert severity="error" variant="outlined">
								{mainMessage}
							</Alert>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="contained"
							size="small"
							onClick={() => this.confirmationClose(false)}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							size="small"
							onClick={() => this.confirmationClose(true)}
						>
							{buttonText}
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

export default UserMenu;
