import { SnackbarProvider } from "notistack";

// Max notifications displayed at once
const max_notifications = 3;
// Time in ms to display notifications
const auto_hide_duration = 3000;
// Notification position settings
const vertical = "top";
const horizontal = "right";

export default function NotificationStackWrapper({ children }) {
  return (
    <SnackbarProvider
      maxSnack={max_notifications}
      autoHideDuration={auto_hide_duration}
      anchorOrigin={{ vertical, horizontal }}
    >
      {children}
    </SnackbarProvider>
  );
}
