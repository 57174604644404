import React from 'react';
import { Chip, Link, Typography, Autocomplete, Button, TextField, Grid, Dialog, DialogContent, DialogTitle, DialogActions, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import PeopleIcon from "@mui/icons-material/People";
import { enqueueSnackbar } from "notistack";

class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = { teams: [], selection: '', members: [], open: false };
        this.loadTeams = this.loadTeams.bind(this);
        this.createTeam = this.createTeam.bind(this);
        this.joinTeam = this.joinTeam.bind(this);
        this.leaveTeam = this.leaveTeam.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShowNotification = this.handleShowNotification.bind(this);
    }

    handleShowNotification (notificationText, notificationVariant) {
        enqueueSnackbar(notificationText, {variant: notificationVariant});
    }

    componentDidMount() {
        if (typeof this.props.myTeam === 'undefined' || this.props.myTeam === null || this.props.myTeam.id === 0) {
            this.loadTeams('');
        } else {
            fetch('api/team/getMembers?teamId=' + this.props.myTeam.id).then(resp => resp.json())
                .then(resp => this.setState({ members: resp.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : (b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0)) }));
        }
    }

    componentDidUpdate(prevProps) {
        if ((typeof this.props.myTeam === 'undefined' || this.props.myTeam === null || this.props.myTeam.id === 0)
            && prevProps.myTeam !== this.props.myTeam) {
            this.loadTeams('');
        }
    }

    loadTeams(searchTerm) {
        fetch('api/team/getAll?searchTerm=' + encodeURIComponent(searchTerm)).then(resp => resp.json())
            .then(resp => this.setState({ teams: resp }));
    }

    createTeam(newTeam) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                encryptedAthleteId: this.props.athleteId,
                teamName: newTeam
            })
        };
        fetch('api/team/create', requestOptions).then(resp => resp.json()).then(resp => {
            if (resp > -1) {
                let { teams } = this.state;
                teams.push({ id: resp, name: newTeam });
                this.setState({ teams: teams }, this.joinTeam(resp));
                this.handleShowNotification("You have created team " + newTeam, "success");
            } else {
                this.handleShowNotification("Could not create team with name " + newTeam, "error");
            }
        });
    }

    joinTeam(teamId) {
        let { teams } = this.state;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                encryptedAthleteId: this.props.athleteId,
                teamId: teamId
            })
        };
        fetch('api/team/join', requestOptions).then(resp => resp.json())
            .then(resp => {
                if (resp === 'OK') {
                    fetch('/api/team/getMembers?teamId=' + teamId).then(result => result.json())
                        .then(result => this.setState({ members: result.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : (b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0)) }, this.props.update(teams.find(team => team.id === teamId))));
                    this.handleShowNotification("You have joined the team", "success");
                } else {
                    this.handleShowNotification("There was an error joining the team: " + resp, "error");
                }
            });
    }

    leaveTeam() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                encryptedAthleteId: this.props.athleteId,
                teamId: this.props.myTeam.id,
                utcOffset: new Date().getTimezoneOffset()
            })
        };
        fetch('api/team/leave', requestOptions).then(resp => resp.json()).then(resp => {
            if (resp === 'OK') {
                this.setState({ selection: '', members: [] }, this.props.update({ id: 0 }));
                this.handleShowNotification("You have left the team!","success");
            } else {
                this.handleShowNotification(resp, "error");
            }
        });
    }

    handleChange(unusedEvent, value) {
        if (value === null) {
            value = '';
        }
        this.setState({ selection: value });
    }

    handleClick() {
        let { teams, selection } = this.state;
        let index = teams.findIndex(team => team.name === selection);
        if (index < 0) {
            this.createTeam(selection.trim());
        } else {
            this.joinTeam(teams[index].id)
        }
    }

    handleClickOpen() {
        this.setState({ open : true })
    }

    handleClose() {
        this.setState({ open : false })
    }

    render() {
        let { myTeam } = this.props;
        let { teams, selection, members } = this.state;
        let teamOptions = teams.length > 0 ? teams.map(team => team.name) : [];
        let hasTeam = myTeam.id !== 0;
        let disableJoinButton = selection.trim().length < 4;
        let message = !hasTeam ? 'No team' : (<strong>{myTeam.name}</strong>);
        let content = hasTeam ?
                <List dense>
                    {members.map((member, index) => {
                        return (
                            <ListItem disableGutters key={index}>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={member.name}
                                        src={member.pictureURL}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={member.name} />
                            </ListItem>
                        );
                    })}
                </List>
            :   
            <Grid container direction='column' spacing={3}>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        freeSolo options={teamOptions} sx={{ paddingTop: '6px' }}
                        onInputChange={this.handleChange} onChange={this.handleChange}
                        renderInput={(params) => 
                            <TextField {...params} label='Choose or create a team' color='primary'/>}
                    />
                </Grid>
            </Grid>
        return (
            <>
                <Typography variant="body1" fontWeight={700} display="block">
                    <Link sx={{ cursor:'pointer'}} onClick={this.handleClickOpen}>
                        {message}
                    </Link>
                </Typography>
                        <Dialog
                            open={this.state.open}
                            onClose={this.handleClose}
                            aria-labelledby="responsive-dialog-title"
                            fullWidth={true}
                            maxWidth='xs'
                        >
                            <DialogTitle id="responsive-dialog-title" display="flex" alignItems="center">
                                {myTeam.name}
                                <Chip size="small" icon={<PeopleIcon/>} label={members.length + " Members"} sx={{ ml:1 }} />
                            </DialogTitle>
                            <DialogContent sx={{ py: 0 }}>
                                {content}
                            </DialogContent>
                            <DialogActions>
                                {hasTeam ?
                                    <>
                                        <Button
                                            variant="contained"
                                            onClick={this.handleClose}>
                                            Close
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={this.leaveTeam}>
                                            Leave Team
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Button
                                            variant="contained"
                                            onClick={this.handleClose}>
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={this.handleClick}
                                            disabled={disableJoinButton}>
                                            Join Team
                                        </Button>
                                    </>
                                }
                            </DialogActions>
                        </Dialog>
            </>
        )
    }
}

export default Team;