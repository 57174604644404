import React from 'react';
import { Stepper, Step, StepLabel, StepContent, Button, Alert, Typography } from '@mui/material';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

const clientId = '59896';
const responseType = 'code';
const approvalPrompt = 'auto';
const scope = 'read,profile:read_all,activity:read';

class RegistrationStepper extends React.Component {
    static propTypes = {
		cookies: instanceOf(Cookies).isRequired,
	};

    constructor(props) {
		super(props);
		this.state = {
            streamNumber: null,
            eventSource: null,
            errorMessage: null,
        };
        this.takeAction = this.takeAction.bind(this);
        this.initializeData = this.initializeData.bind(this);
        this.openEventStream = this.openEventStream.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.redirectToStravaAuth = this.redirectToStravaAuth.bind(this);
        this.closeEventStream = this.closeEventStream.bind(this);
        this.saveDeloitteUser = this.saveDeloitteUser.bind(this);
	}

    componentDidMount() {
        this.takeAction();
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.takeAction();
        }
    }

    takeAction() {
        const { currentStep } = this.props;
        switch (currentStep) {
            case 1:
                break;
            case 2:
                this.initializeData();
                break;
            default:
                this.closeEventStream();
        }
    }

    initializeData() {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user) {
            const email = user.mail;
            if (email) {
                fetch('api/getEventStreamNumber?deloitteEmail=' + email)
                    .then(response => response.json())
                    .then(data => {
                        this.openEventStream(data);
                        this.setState({ streamNumber: data });
                    });
            } else {
                console.error('No Deloitte email found; cannot open event stream.');
            }
        } else {
            console.error("User MSAL data doesn't exist");
        }
        
    }

    openEventStream(streamNumber) {
        if (streamNumber) {
            const eventSource = new EventSource(`api/openEventStream/${streamNumber}`);
            eventSource.addEventListener('callback', this.handleCallback);
            this.setState({ eventSource: eventSource });
        } else {
            console.error('No stream number found; cannot open event stream.');
        }
    }

    handleCallback(event) {
        let { stravaWindow } = this.state;
        if (stravaWindow && !stravaWindow.closed) {
            stravaWindow.close();
        }
        const eventData = JSON.parse(event.data);
        switch (eventData.code) {
            case 1:
            case 2:
                this.saveDeloitteUser(JSON.parse(eventData.message));
                break;
            default:
                this.setState({ errorMessage: eventData.message });
                break;
        }
    }    

    closeEventStream() {
        const { eventSource, streamNumber } = this.state;
        if (eventSource) {
            eventSource.close();
            fetch('api/closeEventStream', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: streamNumber
            });
            this.setState({ eventSource: null });
        }
    }

    redirectToStravaAuth() {
        const { target } = this.props;
        const { streamNumber } = this.state;     
        if (streamNumber) {
            const redirectUri = encodeURIComponent(`https://${target}.ddglobalchallenge.com/api/exchange_token/${streamNumber}`);
            const stravaAuthUrl = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&approval_prompt=${approvalPrompt}&scope=${scope}`;
            let stravaWindow = window.open(stravaAuthUrl, '_blank', 'rel=noreferrer');
            this.setState({ stravaWindow: stravaWindow });
        } else {
            console.error('No stream number found; cannot redirect to Strava.');
        }
    }

    redirectToStravaClub() {
        window.location.href = 'https://www.strava.com/clubs/831562';
    }

    redirectToLogin() {
        window.location.href = '/dlogin';
    }

    convertObjectKeysToCamelCase(obj) {
        function kebabToCamel(str) {
            return str.replace(/-./g, x => x[1].toUpperCase()).replace(/^[A-Z]/g, x => x[0].toLowerCase());
        }
        if (obj.HttpOnly) {
            delete obj.HttpOnly;
        }
        let newObj = {};
        for (let key in obj) {
            let camelCaseKey = kebabToCamel(key);
            newObj[camelCaseKey] = obj[key];
        }
        return newObj;
    }

    saveDeloitteUser(newCookie) {
        let user = JSON.parse(sessionStorage.getItem('user'));
        user.encryptedId = newCookie.value;
        let url = "api/saveDeloitteUser";
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(user),
        };
        fetch(url, requestOptions)
            .then(resp => resp.json())
            .then(success => {
                if (success) {
                    this.closeEventStream();
                    const { cookies } = this.props;
                    cookies.set(newCookie.name, newCookie.value, this.convertObjectKeysToCamelCase(newCookie.attributes));
                    sessionStorage.removeItem("user");
                    window.location.href = '/';
                } else {
                    console.error('Failed to save Deloitte user');
                }
            });
    }

    render() {
        const { currentStep } = this.props;
        const { errorMessage } = this.state;
        return (
            <>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                <Stepper sx={{ px:4 }} activeStep={currentStep - 1} orientation="vertical">
                    <Step>
                    <StepLabel><Typography component="h2">Connect Deloitte account</Typography></StepLabel>
                        <StepContent>
                            <Button sx={{mt:1}} onClick={this.redirectToLogin} variant="contained" color="secondary">
                                Login with <strong style={{ marginLeft: '3px'}}>Deloitte<span className='text-deloitte-green'>.</span></strong>
                            </Button>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel><Typography component="h2">Connect Strava account</Typography></StepLabel>
                        <StepContent>
                            <Typography variant="body2">Authorize your Strava account to share your athlete data with us.</Typography>
                            <Button onClick={this.redirectToStravaAuth} sx={{ p: 0, mt:1 }}>
                                <img style={{ maxWidth: 200 }} alt="Connect with Strava" src="https://gotoes.org/strava/images/ConnectWithStrava.png" />
                            </Button>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel><Typography component="h2">Join Strava club</Typography></StepLabel>
                        <StepContent>
                            <Typography variant="body2">Join the <em>Deloitte Digital Global Challenge</em> club on Strava.
                            </Typography>
                            <Button sx={{my:1}} onClick={this.redirectToStravaClub} variant="contained" color="secondary">
                                Join our club <img style={{ maxWidth:'30px', marginLeft: '3px' }} src="/solid/Logo_with_background_original.png" alt="DD Global Challenge circle logo" />
                            </Button>
                            <Typography variant="body2">Our team will manually approve your request to join the club. This may take up to 24 hours.
                            </Typography>
                        </StepContent>
                    </Step>
                </Stepper>
            </>
        );
    }
}

export default withCookies(RegistrationStepper);
